@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500&display=swap);

body {
  background-color: #f2f5fb !important;
  font-family: "Poppins", sans-serif;
}
.middle-wrap {
  min-height: 600px;
}
.theme-styled-group {
  border-bottom: 1px solid #eaeef4;
}
.theme-styled-group .form-control,
.theme-styled-group .form-control:disabled {
  border: none;
  background: #fff;
  padding-left: 0;
  padding-top: 0;
}
.theme-styled-group .form-control {
  font-weight: bold !important;
}
.theme-styled-group .form-control:focus {
  outline: none;
  box-shadow: none;
}
.theme-styled-group .form-control:disabled {
  font-weight: normal !important;
}
.theme-styled-group .form-group {
  margin: 0 0 0 0;
}
.theme-styled-group .form-group label {
  margin: 0 0 0 0;
  color: #93999f;
}
.submit-btn {
  right: 40px;
  top: 50px;
  z-index: 1;
} 
.assosiate-box{
  border-radius: 9px;
background: #f2f5fb !important;
border:0;
}
.home-modals .modal-header {
  position: relative;
  border: 0;
}

.home-modals button.close {
  position: absolute;

  right: 0;
  color: #007bff;
  font-weight: 100;
  top: 0;
  border-top-right-radius: 9px;
  opacity: 1;
  padding: 6px !important;
  margin: 0;
}

.home-modals .modal-content {
  border: 0;
  border-radius: 9px;
}

.home-modals .modal-title.h4 {
  font-weight: 600;
  margin-top: 10px;
}

.home-modals .modal-footer {
  border: 0;
}

.home-modals .modal-body {
  font-size: 14px;
}

.home-modals button.btn.btn-primary {
  padding: 10px 35px;
  margin-bottom: 21px;
}
.contnt-center{
  display: block;
  padding-bottom: 0 !important;
  text-align: center;
}
.check-circle-img{
  margin-top: 10px;
}
.noted-list-pop ul {
  padding: 0;
  text-align: left;
  list-style: none;
}

.noted-list-pop {
  text-align: center;
  max-width: 355px;
  margin: auto;
  width: -webkit-max-content;
  width: max-content;
}
.noted-list-pop ul li{
  line-height: 3em;
  font-size: 14px;
  color: #ef5632;
}
.noted-list-pop ul li svg{
  margin-right: 15px;
}
.submit-btn-orange{
  background: #ef5732;
  padding: 8px 60px !important;
  font-size: 18px;
  border: 0;
  border-radius: 10px;
  margin-bottom: 15px !important;
}
.submit-btn-orange:hover{
background: #bd2130;
}
button.back-btn-orange.btn.btn-primary {
  color: #ef5732;
  background: transparent;
  border: 0;
  font-size: 15px;
  padding: 0;
  text-decoration: underline;
}
button.back-btn-orange.btn.btn-primary:hover{
  opacity: .6;
}
.divaddress {font-size: 13px;color: #6b6b6b;text-align: center;min-height: 40px;}
.dminHeight{min-height: 50px;}
.green-bgbtns button {
  background: #13d570 !important;
  color: #ffffff !important;
  border: 1px solid #13d570;
  padding: 8px 30px;
}
.bgsuccess{
  background: #d5f1e2;
  color: #38a76d!important;
}
.bgwarning
{
  background: #ffdcdc;
  color: #f16647!important;
}
/*-------------------------------------------------------------------*/

/* === Landing screens === */

/* Auth */

.auth .lock-profile-img {
  width: 90px;
  height: 90px;
  border-radius: 100%;
}

.auth .auth-form-light {
  background: #ffffff;
}

.auth .auth-form-light select {
  color: #c9c8c8;
}

.auth .auth-form-light .input-group .form-control:focus,
.auth .auth-form-light .asColorPicker-wrap .form-control:focus,
.auth .auth-form-light .input-group .asColorPicker-input:focus,
.auth .auth-form-light .asColorPicker-wrap .asColorPicker-input:focus,
.auth .auth-form-light .input-group .dataTables_wrapper select:focus,
.dataTables_wrapper .auth .auth-form-light .input-group select:focus,
.auth .auth-form-light .asColorPicker-wrap .dataTables_wrapper select:focus,
.dataTables_wrapper .auth .auth-form-light .asColorPicker-wrap select:focus,
.auth
  .auth-form-light
  .input-group
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"]:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-light
  .input-group
  input[type="text"]:focus,
.auth
  .auth-form-light
  .asColorPicker-wrap
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"]:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-light
  .asColorPicker-wrap
  input[type="text"]:focus,
.auth
  .auth-form-light
  .input-group
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-light
  .input-group
  select:focus,
.auth
  .auth-form-light
  .asColorPicker-wrap
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-light
  .asColorPicker-wrap
  select:focus,
.auth
  .auth-form-light
  .input-group
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"]:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-light
  .input-group
  input[type="number"]:focus,
.auth
  .auth-form-light
  .asColorPicker-wrap
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"]:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-light
  .asColorPicker-wrap
  input[type="number"]:focus,
.auth
  .auth-form-light
  .input-group
  .select2-container--default
  .select2-selection--single:focus,
.select2-container--default
  .auth
  .auth-form-light
  .input-group
  .select2-selection--single:focus,
.auth
  .auth-form-light
  .asColorPicker-wrap
  .select2-container--default
  .select2-selection--single:focus,
.select2-container--default
  .auth
  .auth-form-light
  .asColorPicker-wrap
  .select2-selection--single:focus,
.auth
  .auth-form-light
  .input-group
  .select2-container--default
  .select2-selection--single
  .select2-search__field:focus,
.select2-container--default
  .select2-selection--single
  .auth
  .auth-form-light
  .input-group
  .select2-search__field:focus,
.auth
  .auth-form-light
  .asColorPicker-wrap
  .select2-container--default
  .select2-selection--single
  .select2-search__field:focus,
.select2-container--default
  .select2-selection--single
  .auth
  .auth-form-light
  .asColorPicker-wrap
  .select2-search__field:focus,
.auth .auth-form-light .input-group .typeahead:focus,
.auth .auth-form-light .asColorPicker-wrap .typeahead:focus,
.auth .auth-form-light .input-group .tt-query:focus,
.auth .auth-form-light .asColorPicker-wrap .tt-query:focus,
.auth .auth-form-light .input-group .tt-hint:focus,
.auth .auth-form-light .asColorPicker-wrap .tt-hint:focus,
.auth .auth-form-light .input-group .form-control:active,
.auth .auth-form-light .asColorPicker-wrap .form-control:active,
.auth .auth-form-light .input-group .asColorPicker-input:active,
.auth .auth-form-light .asColorPicker-wrap .asColorPicker-input:active,
.auth .auth-form-light .input-group .dataTables_wrapper select:active,
.dataTables_wrapper .auth .auth-form-light .input-group select:active,
.auth .auth-form-light .asColorPicker-wrap .dataTables_wrapper select:active,
.dataTables_wrapper .auth .auth-form-light .asColorPicker-wrap select:active,
.auth
  .auth-form-light
  .input-group
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"]:active,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-light
  .input-group
  input[type="text"]:active,
.auth
  .auth-form-light
  .asColorPicker-wrap
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"]:active,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-light
  .asColorPicker-wrap
  input[type="text"]:active,
.auth
  .auth-form-light
  .input-group
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select:active,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-light
  .input-group
  select:active,
.auth
  .auth-form-light
  .asColorPicker-wrap
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select:active,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-light
  .asColorPicker-wrap
  select:active,
.auth
  .auth-form-light
  .input-group
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"]:active,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-light
  .input-group
  input[type="number"]:active,
.auth
  .auth-form-light
  .asColorPicker-wrap
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"]:active,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-light
  .asColorPicker-wrap
  input[type="number"]:active,
.auth
  .auth-form-light
  .input-group
  .select2-container--default
  .select2-selection--single:active,
.select2-container--default
  .auth
  .auth-form-light
  .input-group
  .select2-selection--single:active,
.auth
  .auth-form-light
  .asColorPicker-wrap
  .select2-container--default
  .select2-selection--single:active,
.select2-container--default
  .auth
  .auth-form-light
  .asColorPicker-wrap
  .select2-selection--single:active,
.auth
  .auth-form-light
  .input-group
  .select2-container--default
  .select2-selection--single
  .select2-search__field:active,
.select2-container--default
  .select2-selection--single
  .auth
  .auth-form-light
  .input-group
  .select2-search__field:active,
.auth
  .auth-form-light
  .asColorPicker-wrap
  .select2-container--default
  .select2-selection--single
  .select2-search__field:active,
.select2-container--default
  .select2-selection--single
  .auth
  .auth-form-light
  .asColorPicker-wrap
  .select2-search__field:active,
.auth .auth-form-light .input-group .typeahead:active,
.auth .auth-form-light .asColorPicker-wrap .typeahead:active,
.auth .auth-form-light .input-group .tt-query:active,
.auth .auth-form-light .asColorPicker-wrap .tt-query:active,
.auth .auth-form-light .input-group .tt-hint:active,
.auth .auth-form-light .asColorPicker-wrap .tt-hint:active {
  border-color: #eee;
}

.auth .auth-form-transparent {
  background: transparent;
}

.auth .auth-form-transparent .form-control,
.auth .auth-form-transparent .asColorPicker-input,
.auth .auth-form-transparent .dataTables_wrapper select,
.dataTables_wrapper .auth .auth-form-transparent select,
.auth
  .auth-form-transparent
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-transparent
  input[type="text"],
.auth .auth-form-transparent .jsgrid .jsgrid-table .jsgrid-filter-row select,
.jsgrid .jsgrid-table .jsgrid-filter-row .auth .auth-form-transparent select,
.auth
  .auth-form-transparent
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-transparent
  input[type="number"],
.auth
  .auth-form-transparent
  .select2-container--default
  .select2-selection--single,
.select2-container--default
  .auth
  .auth-form-transparent
  .select2-selection--single,
.auth
  .auth-form-transparent
  .select2-container--default
  .select2-selection--single
  .select2-search__field,
.select2-container--default
  .select2-selection--single
  .auth
  .auth-form-transparent
  .select2-search__field,
.auth .auth-form-transparent .typeahead,
.auth .auth-form-transparent .tt-query,
.auth .auth-form-transparent .tt-hint,
.auth .auth-form-transparent .input-group-text {
  border-color: #a3a4a5;
}

.auth .auth-form-transparent .form-control:focus,
.auth .auth-form-transparent .asColorPicker-input:focus,
.auth .auth-form-transparent .dataTables_wrapper select:focus,
.dataTables_wrapper .auth .auth-form-transparent select:focus,
.auth
  .auth-form-transparent
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"]:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-transparent
  input[type="text"]:focus,
.auth
  .auth-form-transparent
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-transparent
  select:focus,
.auth
  .auth-form-transparent
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"]:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-transparent
  input[type="number"]:focus,
.auth
  .auth-form-transparent
  .select2-container--default
  .select2-selection--single:focus,
.select2-container--default
  .auth
  .auth-form-transparent
  .select2-selection--single:focus,
.auth
  .auth-form-transparent
  .select2-container--default
  .select2-selection--single
  .select2-search__field:focus,
.select2-container--default
  .select2-selection--single
  .auth
  .auth-form-transparent
  .select2-search__field:focus,
.auth .auth-form-transparent .typeahead:focus,
.auth .auth-form-transparent .tt-query:focus,
.auth .auth-form-transparent .tt-hint:focus,
.auth .auth-form-transparent .form-control:active,
.auth .auth-form-transparent .asColorPicker-input:active,
.auth .auth-form-transparent .dataTables_wrapper select:active,
.dataTables_wrapper .auth .auth-form-transparent select:active,
.auth
  .auth-form-transparent
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"]:active,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-transparent
  input[type="text"]:active,
.auth
  .auth-form-transparent
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select:active,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-transparent
  select:active,
.auth
  .auth-form-transparent
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"]:active,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  .auth-form-transparent
  input[type="number"]:active,
.auth
  .auth-form-transparent
  .select2-container--default
  .select2-selection--single:active,
.select2-container--default
  .auth
  .auth-form-transparent
  .select2-selection--single:active,
.auth
  .auth-form-transparent
  .select2-container--default
  .select2-selection--single
  .select2-search__field:active,
.select2-container--default
  .select2-selection--single
  .auth
  .auth-form-transparent
  .select2-search__field:active,
.auth .auth-form-transparent .typeahead:active,
.auth .auth-form-transparent .tt-query:active,
.auth .auth-form-transparent .tt-hint:active,
.auth .auth-form-transparent .input-group-text:focus,
.auth .auth-form-transparent .input-group-text:active {
  border-color: #a3a4a5;
}

.auth .auth-form-transparent select {
  outline-color: #a3a4a5;
}

.auth.auth-img-bg {
  padding: 0;
}

@media (min-width: 768px) {
  .auth.auth-img-bg .auth-form-transparent {
    width: 55%;
    margin: auto;
  }
}

.auth .brand-logo {
  margin-bottom: 2rem;
}

.auth .brand-logo img {
  width: 100%;
}

.auth form .form-group {
  margin-bottom: 1.5rem;
}

.auth form .form-group label {
  font-size: 0.8125rem;
}

.auth form .form-group .form-control,
.auth form .form-group .asColorPicker-input,
.auth form .form-group .dataTables_wrapper select,
.dataTables_wrapper .auth form .form-group select,
.auth
  form
  .form-group
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="text"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  form
  .form-group
  input[type="text"],
.auth form .form-group .jsgrid .jsgrid-table .jsgrid-filter-row select,
.jsgrid .jsgrid-table .jsgrid-filter-row .auth form .form-group select,
.auth
  form
  .form-group
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type="number"],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .auth
  form
  .form-group
  input[type="number"],
.auth form .form-group .select2-container--default .select2-selection--single,
.select2-container--default .auth form .form-group .select2-selection--single,
.auth
  form
  .form-group
  .select2-container--default
  .select2-selection--single
  .select2-search__field,
.select2-container--default
  .select2-selection--single
  .auth
  form
  .form-group
  .select2-search__field,
.auth form .form-group .typeahead,
.auth form .form-group .tt-query,
.auth form .form-group .tt-hint {
  background: transparent;
  border-radius: 0;
  font-size: 0.9375rem;
}

.auth form .auth-form-btn {
  height: 50px;
  line-height: 36px;
}

.auth form .auth-link {
  font-size: 0.875rem;
}

.auth form .auth-link:hover {
  color: initial;
}

.page-body-wrapper {
  min-height: calc(100vh - 59px);
  display: flex;
  flex-direction: row;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0px;
}

.page-body-wrapper.full-page-wrapper {
  width: 100%;
  min-height: 100vh;
  padding-top: 0;
}

.main-panel {
  transition: width 0.25s ease, margin 0.25s ease;
    width: calc(100% - 239px);
    min-height: calc(100vh - 59px);
    display: flex;
    flex-direction: column;
    margin-left:239px
}

@media (max-width: 991px) {
  .main-panel {
    margin-left: 0;
    width: 100%;
  }
}

.content-wrapper {
  background: #fafafa;
  padding: 1.1rem 2.5rem 2.5rem;
  width: 100%;
  flex-grow: 1;
}

@media (max-width: 767px) {
  .content-wrapper {
    padding: 1.5rem 1.5rem;
  }
}
.input-group-append .input-group-text,
.input-group-prepend .input-group-text {
  border-color: #ced4da !important;
  padding: 9px !important;
  color: #c9c8c8 !important;
  border-left: none;
  border-radius: 0px 3px 3px 0px !important;
}
.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-prepend .fc button,
.fc .input-group-prepend button,
.input-group-prepend .ajax-upload-dragdrop .ajax-file-upload,
.ajax-upload-dragdrop .input-group-prepend .ajax-file-upload,
.input-group-prepend .swal2-modal .swal2-buttonswrapper .swal2-styled,
.swal2-modal .swal2-buttonswrapper .input-group-prepend .swal2-styled,
.input-group-prepend .wizard > .actions a,
.wizard > .actions .input-group-prepend a,
.input-group-append .btn,
.input-group-append .fc button,
.fc .input-group-append button,
.input-group-append .ajax-upload-dragdrop .ajax-file-upload,
.ajax-upload-dragdrop .input-group-append .ajax-file-upload,
.input-group-append .swal2-modal .swal2-buttonswrapper .swal2-styled,
.swal2-modal .swal2-buttonswrapper .input-group-append .swal2-styled,
.input-group-append .wizard > .actions a,
.wizard > .actions .input-group-append a {
  position: relative;
  z-index: 2;
}
.input-group-text1 {
  display: flex;
  align-items: center;
  padding: 0.875rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  align-items: center;

}
.mandatory{
  color: #ff374a;
}
.mandatoryBorder{
  border-bottom: #ff374a solid 1px!important;
}
.borderNone{
  border: none;
  outline: none;
}
.alignCenter{
  text-align: center;
}
.nomini-submt-withdraw {
  position: relative!important;
}
.nomini-submt-withdraw> h3 {
  text-align: center!important;
}
.btnbottom {
  bottom: 25px;
  position: absolute;
  width: 100%;
  margin-left: -1.25rem;
}
.assosiate-box{
height:100%!important
}
.divcardbody{
  margin-bottom:55px;
}
.imgdiv{
  height: 120px;
  width: 120px!important;
}
.divoverflowY{
  overflow-x: hidden;
  overflow-y: auto;
}
input, .form-control:focus, .asColorPicker-input:focus, .dataTables_wrapper select:focus, .jsgrid .jsgrid-table .jsgrid-filter-row input[type="text"]:focus, .jsgrid .jsgrid-table .jsgrid-filter-row select:focus, .jsgrid .jsgrid-table .jsgrid-filter-row input[type="number"]:focus, .select2-container--default .select2-selection--single:focus, .select2-container--default .select2-selection--single .select2-search__field:focus, .typeahead:focus, .tt-query:focus, .tt-hint:focus, input:focus, select:focus, textarea:focus, button:focus
{
  outline: none !important;
  outline-width: 0;
  outline-color: transparent;
  box-shadow: none!important;
  outline-style: none;
}
.elect-text {
  font-weight: bold;
  font-size: 24px;
  color:rgb(62, 60, 79);
  font-family: Roboto, sans-serif;
}
.textDec {
  font-size: 14px;
  font-weight: bold;
  color:rgb(62, 60, 79);
}
.form-control-lg {
  height: 3.5rem;
  padding: 0.94rem 1.94rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.no_selection {
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}
.min-btn-width{
  min-width: 95px;
}
.min-btn-height
{
  min-height: 50px;
  padding: 0px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
.margin5{
  margin-bottom: .5rem!important;
}
.hidediv{
  visibility: hidden;
}
.iconMinWidth{
  min-width: 24px;
}
.iconMinWidth2{
  min-width: 20px;
}
.cursotpointer{
  cursor:pointer;
}
.cursotpointer:hover{
  text-decoration:none!important;
}
.display-none{
  display: none !important;
}
.display-block{
  display: block;
}
.linkbtn{
  color:#007bff!important;
  cursor: pointer!important;
  text-decoration: none!important;
}
.linkbtn:hover{
  color:#075eba!important;
  text-decoration: none!important;
}
.withdrawed-btns {
  background-color: #e53414;
  color: #fff;
  border: 2px solid #e93717;
  font-size: 13px;
  border-radius: 9px;
  font-weight: 600;
  float: right;
  display: block;
  padding: 7px 6px;
  text-transform: uppercase;
  min-width: 106px;
}
.rejectionNote{
  display: inline-flex;
  font-size: 13px;
}
.nomargin{
  margin-bottom: 0PX;;
}
.popupmsg{
  margin-top: 20px!important;
  font-size: 20px;
  line-height: 1.2;
  }
.popupfooter
{
    justify-content: center!important;
    margin-top: 35px!important;
}
.btnMargin{
margin-bottom: 30px;
}
.warning-orange
{
  background-color: #ff7600!important;
    border: 2px solid #ff7600!important;
    color: #fff;
    font-size: 13px;
    border-radius: 9px;
    font-weight: 600;
    float: right;
    display: block;
    padding: 7px 6px;
    text-transform: uppercase;
    min-width: 106px;
}
.Approved-btns {
  background-color: #2f7642;
  border: 2px solid #2f7642;
  color: #ffffff;
  font-size: 13px;
  border-radius: 9px;
  font-weight: 600;
  float: right;
  display: block;
  padding: 7px 6px;
  text-transform: uppercase;
  min-width: 106px;
}
.Pending-btns {
  background-color: #ffad18;
  border: 2px solid #ffad18;
  color: #ffffff;
  font-size: 13px;
  border-radius: 9px;
  font-weight: 600;
  float: right;
  display: block;
  padding: 7px 6px;
  text-transform: uppercase;
  min-width: 106px;
}
.noDataMsg{
  margin-left: 20px;
    margin-top: 20px;
}
.b {
  font-weight: bold !important;
}
#notfounddiv .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}

.notfound {
  max-width: 410px;
  width: 100%;
  text-align: center;
}

.notfound p {
font-family: montserrat,sans-serif;
color: #000;
font-size: 14px;
font-weight: 400;
margin-bottom: 20px;
margin-top: 0;
font-size: 20px;
}
#notfounddiv {
top: 50%;
left: 50%;
-webkit-transform: translateY(-50%) translateX(-50%);
        transform: translateY(-50%) translateX(-50%);
width: 100%;
position: absolute;
}
.min-height-unset{
  min-height: unset!important;
}
.wraptxt{
  white-space: pre-wrap;
  overflow-wrap: anywhere;
}
.divGray{
  background: #647a95!important;
  color: #ffffff!important;
  }
.size25{
  font-size: 25px;
  }
.divInline{
  display: inline-flex;
  }
.maxVoteDiv{
  background: #ffdcdc;
  color: #f16e47;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  margin-right: 10px;
 }
 .LoginInput{
  background: transparent;
  border-radius: 0;
  font-size: 0.9375rem;
 }

 .borderRed{
   border: red !important;
 }
.navbar-light .navbar-nav .nav-link{
    color: #ffffff;
}

nav svg{
    width: 30px;
    margin-right: 20px;
}
.grid-row{
   margin-top: 40px;
   position: relative;
}
.grid-link svg{
   width: 46px !important;
   margin-bottom: 12px;
}
.grid-link .badge{
    top: 47%;
    border-radius: 50%;
    margin-top: -45px;
    margin-left: 20px;
    height: 22px;
    width: 22px;
    line-height: 17px;
}

.blue-land-btns button{
   border-radius: 9px;
}
input label::before {
  border:1px solid red;
  display: none;
}

.notification-card.card{
    border-radius: 9px;
      padding: 5px;
      border:0;
  }
  .nomini-form .card{
    border-radius: 9px;
    padding: 5px;
    border:0;
  }
  .nomini-form .form-group select{
    border:0;
    outline: none;
  }
  .nomini-form  .btn-link{
    border: 0;
    color:#000;
    font-size: 16px;
    text-decoration: none;
    outline: none;
  }
  .nomini-form .btn-primary{
    border:0;
    padding: 10px 20px;
    font-size: 16px;
    outline: none;
    background-image: linear-gradient(to right bottom, #25d49c, #18d592, #0dd587, #0ad57b, #12d56e);
  }

  /**************************************************/
  input#formBasicCheckbox\ green-form-chk-gender-agree {
    position: absolute !important;
    opacity: 0 !important;
  }
  input#formBasicCheckbox\ green-form-chk-gender-agree + label {
    position: relative !important;
    cursor: pointer !important;
    padding: 0 !important;
    text-align: left;
  }
  input#formBasicCheckbox\ green-form-chk-gender-agree + label:before {
    content: "" !important;
    margin-right: 10px !important;
    display: inline-block !important;
    vertical-align: text-top !important;
    width: 20px !important;
    position: absolute;
    left:-33px;
    border-radius: 3px;
    border: 3px solid red;
    height: 20px;
    background: #fff;
  }
  input#formBasicCheckbox\ green-form-chk-gender-agree:hover + label:before {
    background: #25d49c !important;
  }
  input#formBasicCheckbox\ green-form-chk-gender-agree:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12) !important;
  }
  input#formBasicCheckbox\ green-form-chk-gender-agree:checked + label:before {
    background: #25d49c !important;
  }
  input#formBasicCheckbox\ green-form-chk-gender-agree:disabled + label {
    color: #b8b8b8 !important;
    cursor: auto !important;
  }
  input#formBasicCheckbox\ green-form-chk-gender-agree:disabled + label:before {
    box-shadow: none !important;
    background: #ddd !important;
  }
  input#formBasicCheckbox\ green-form-chk-gender-agree:checked + label:after {
    content: "" !important;
    position: absolute !important;
    left: -28px !important;
    top: 9px !important;
    background: white !important;
    width: 2px !important;
    height: 2px !important;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white,
     4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white !important;
    -webkit-transform: rotate(45deg) !important;
            transform: rotate(45deg) !important;
  }

  /*************************************************/

  input#formBasicCheckbox\ green-form-chk-agree {
    position: absolute !important;
    opacity: 0 !important;
  }
  input#formBasicCheckbox\ green-form-chk-agree + label {
    position: relative !important;
    cursor: pointer !important;
    padding: 0 !important;
    text-align: left;
  }
  input#formBasicCheckbox\ green-form-chk-agree + label:before {
    content: "" !important;
    margin-right: 10px !important;
    display: inline-block !important;
    vertical-align: text-top !important;
    width: 20px !important;
    position: absolute;
    left:-33px;
    border-radius: 3px;
    border: 3px solid red;
    height: 20px;
    background: #fff;
  }
  input#formBasicCheckbox\ green-form-chk-agree:hover + label:before {
    background: #25d49c !important;
  }
  input#formBasicCheckbox\ green-form-chk-agree:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12) !important;
  }
  input#formBasicCheckbox\ green-form-chk-agree:checked + label:before {
    background: #25d49c !important;
  }
  input#formBasicCheckbox\ green-form-chk-agree:disabled + label {
    color: #b8b8b8 !important;
    cursor: auto !important;
  }
  input#formBasicCheckbox\ green-form-chk-agree:disabled + label:before {
    box-shadow: none !important;
    background: #ddd !important;
  }
  input#formBasicCheckbox\ green-form-chk-agree:checked + label:after {
    content: "" !important;
    position: absolute !important;
    left: -28px !important;
    top: 9px !important;
    background: white !important;
    width: 2px !important;
    height: 2px !important;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white,
     4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white !important;
    -webkit-transform: rotate(45deg) !important;
            transform: rotate(45deg) !important;
  }
  
  /*************************/
  
  input#formBasicCheckbox\ green-form-chk {
    display: none;
    position: absolute;
    opacity: 0;
  }
  input#formBasicCheckbox\ green-form-chk + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }
  input#formBasicCheckbox\ green-form-chk + label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    position: absolute;
    left:-33px;
    vertical-align: text-top;
    width: 20px;
    border-radius: 3px;
    border: 3px solid red;
    height: 20px;
    background: #fff;
  }
  input#formBasicCheckbox\ green-form-chk:hover + label:before {
    background: #25d49c;
  }
  input#formBasicCheckbox\ green-form-chk:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }
  input#formBasicCheckbox\ green-form-chk:checked + label:before {
    background: #25d49c;
  }
  input#formBasicCheckbox\ green-form-chk:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }
  input#formBasicCheckbox\ green-form-chk:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }
  input#formBasicCheckbox\ green-form-chk:checked + label:after {
    content: "";
    position: absolute;
    left: -28px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  .nomini-date button.react-date-picker__calendar-button.react-date-picker__button{
    display: none;
  }
  .nomini-date .react-date-picker__wrapper{
    border:0;
  }
  .nomini-submt-sect{
    border-top-right-radius: 9px;
    border-top-left-radius: 9px;
    padding-bottom: 35px;
    background-color: #fff;
  }
  .nomini-submt-sect h3{
    background-color: #007bff!important;
    color:#fff;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    font-size: 18px;
    text-transform: capitalize;
    padding:20px;
    font-weight:600;
  }
  .list-nomini-sub {
    padding: 5px 13px;
    border-bottom: 1px solid #f4f7f9;
    margin-bottom: 5px;
}

.list-nomini-sub h4 {
    font-size: 17px;
    font-weight: 600;
   margin-bottom: 3px;
}

.list-nomini-sub h6 {
    font-size: 13px;
    color: #6f7b8b;
}
.list-nomini-sub h6 b{
    color:#000;
}
.list-nomini-sub button.withdraw-btns{
  background-color: #fff;
  border: 2px solid #f16c54;
  color: #f16c54;
  font-size: 13px;
  border-radius: 9px;
  font-weight: 600;
  float: right;
  display: block;
  padding: 7px 6px;
  text-transform: uppercase;
  min-width: 106px;
}
.list-nomini-sub button.withdraw-btns:hover{
  background-color: #f16c54;
  color:#fff;
}
.width-position{
  position: relative;
}
.nomini-submt-sect{
  position: absolute;
  top:0;
  right:-35px;
  height:100%;
  min-width: 300px;
  max-width: 800px;
  margin: auto;
}
.nomini-form-width{
  margin-left: -20px;
}
@media (max-width: 1230px){
  .nomini-submt-sect{
    right:-30px;
  }
  .nomini-form-width{
    margin-left: -30px;
  }
}
@media (max-width: 1200px){
  .list-nomini-sub button.withdraw-btns{
    padding: 9px 11px;
  }
  .nomini-submt-sect{
    position: relative;
    right: auto;
    top:auto;
    margin:20px auto 0 auto;
    max-width: 400px;
    height: auto;
    min-width: 320px;
  }
  .nomini-form-width{
    margin-left: 0px;
  }
}
@media (max-width: 600px){
  .list-nomini-sub h6 b{
    display: block;
  }
  .list-nomini-sub button.withdraw-btns{
    margin-top: 10px;
  }
}
.voting-left.card {
    padding: 0;
    display: block !important;
    border-radius: 9px;
    border: 0;
}

.voting-left.card .card-body {
    padding: 0;
    display: block;
    border-radius: 9px !important;
}
.voting-left.card .card-body .nav-pills .nav-link.active,.voting-left.card .card-body .nav-pills .show>.nav-link{
    background:#d6e8ff;
    display: block;
    border-top-left-radius: 9px !important;
    border-top-right-radius: 9px !important;
    color:#000;
}
.voting-left .card-header-pills{
    margin:0;
}
.voting-left .nav-pills .nav-link{
    color:#000;
    font-size: 14px;
    padding:20px 20px;
    border-bottom: 1px solid #f4f6f9;
}
.green-btns button{
background: #fff;

color: #13d570;
border:1px solid #13d570;
padding:8px 30px;
}
.green-btns button:hover{
    border:0;
    color:#fff;
    background: #13d570;
}
.green-btns button.active {
    background: #13d570 !important;
    border: 0;
}
.save-blue-btn button{
    font-size: 16px;
    padding:6px 33px !important;
}
.submit-btn{
    background: #ef5732;
    padding:6px 22px;
    font-size: 18px;
    border:0;
    border-radius:10px;
}
.voting-sect .fade {
    height: auto;
 }
.voting-sect .fade:not(.show) {
    overflow: hidden;  /* Hide the element content, while height = 0 */
    height: 0;

}
.voting-sect .card{
    border:0;
    border-radius: 9px;
}
.vote-height-over{
    height: 620px !important;
    overflow-x: hidden;
    overflow-y: scroll;

}
/**********************slider page********************************/
.president-slid {
    background: #fff;
    padding: 50px 55px;
    border-radius: 10px;
  }
  .president-hds {
    text-align: center;
    margin: 0px 0 30px 0;
    width: 100%;
    position: relative;
  }
  .president-hds span {
    background: #f5f6f8;
    color: #000;
    padding: 10px 25px;
    display: inline-block;
    border-radius: 17px;
    text-transform: capitalize;
    font-weight: 600;
    position: relative;
    z-index: 9;
    font-size: 20px;
  }
  .president-hds span i {
    color: #a5abb8;
  }
  .president-hds:before {
    content: "";
    background: #eeeeee;
    height: 1px;
    width: 40%;
    left: 0;
    /* padding-top: 0;  */
    top: 50%;
    position: absolute;
    display: inline-block;
  }
  .president-hds:after {
    content: "";
    background: #eeeeee;
    height: 1px;
    width: 40%;
    right: 0;
    /* padding-top: 0; */
    top: 50%;
    position: absolute;
    display: inline-block;
  }
  .other-list {
    text-align: center;
    width: 100%;
    position: relative;
  }
  .other-list span {
    background: #fff;
    color: #868791;
    padding: 10px 25px;
    display: inline-block;
    border-radius: 17px;
    font-weight: 600;
    position: relative;
    text-transform: uppercase;
    z-index: 9;
    font-size: 18px;
  }
  .other-list:before {
    content: "";
    background: #eeeeee;
    height: 1px;
    width: 40%;
    left: 0;
    /* padding-top: 0;  */
    top: 50%;
    position: absolute;
    display: inline-block;
  }
  .other-list:after {
    content: "";
    background: #eeeeee;
    height: 1px;
    width: 40%;
    right: 0;
    /* padding-top: 0; */
    top: 50%;
    position: absolute;
    display: inline-block;
  }
  .president-slid .carousel-indicators {
    display: none;
  }
  .president-slid .carousel-control-prev {
    width: 40px;
    background: #eeeeee;
    height: 40px;
    top: 50%;
    left: -45px;
    opacity: 1;
    border-radius: 50px;
  }
  .president-slid .carousel-control-next {
    width: 40px;
    background: #eeeeee;
    height: 40px;
    opacity: 1;
    top: 50%;
    border-radius: 50px;
    right: -45px;
  }
  .president-slid .carousel-control-next-icon {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAQCAYAAAAvf+5AAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDA2IDc5LjE2NDc1MywgMjAyMS8wMi8xNS0xMTo1MjoxMyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIyLjMgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjZCQjMxNTFEQzQ2RTExRUJCMEQ0RDJBQTE5QUZBNEFDIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjZCQjMxNTFFQzQ2RTExRUJCMEQ0RDJBQTE5QUZBNEFDIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NkJCMzE1MUJDNDZFMTFFQkIwRDREMkFBMTlBRkE0QUMiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NkJCMzE1MUNDNDZFMTFFQkIwRDREMkFBMTlBRkE0QUMiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5F8UOoAAAA2ElEQVR42mKsrq42YWRkXAPEK5ubmysYGBj+M2ABTEAFSUBa/v///2W1tbUTgGxGrAr//fvXCFR8DcQBKs6rqamZjk0x8+HDh7/a2NisZWJi8gLyRYHYxM7OTuLQoUNbURSCCGIUM8MYhBQzIxuPTzFWH9bX10sBPbkf6Dk1qCdLmbAp/PPnDyNQEm4IMFT+M2ExTQIosRvIVIUqmtDS0tLLiK7o79+/+4BMTZgiYGwVMiAHLD5FcIWEFIHFiFEEjmugD+sJKQIrBIJ5QPohEHfiUgQCAAEGAAbEfCwI4OIGAAAAAElFTkSuQmCC) no-repeat !important;
  }
  .president-slid .carousel-control-prev-icon {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAQCAYAAAAvf+5AAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDA2IDc5LjE2NDc1MywgMjAyMS8wMi8xNS0xMTo1MjoxMyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIyLjMgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjZCQjMxNTFEQzQ2RTExRUJCMEQ0RDJBQTE5QUZBNEFDIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjZCQjMxNTFFQzQ2RTExRUJCMEQ0RDJBQTE5QUZBNEFDIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NkJCMzE1MUJDNDZFMTFFQkIwRDREMkFBMTlBRkE0QUMiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NkJCMzE1MUNDNDZFMTFFQkIwRDREMkFBMTlBRkE0QUMiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5F8UOoAAAA2ElEQVR42mKsrq42YWRkXAPEK5ubmysYGBj+M2ABTEAFSUBa/v///2W1tbUTgGxGrAr//fvXCFR8DcQBKs6rqamZjk0x8+HDh7/a2NisZWJi8gLyRYHYxM7OTuLQoUNbURSCCGIUM8MYhBQzIxuPTzFWH9bX10sBPbkf6Dk1qCdLmbAp/PPnDyNQEm4IMFT+M2ExTQIosRvIVIUqmtDS0tLLiK7o79+/+4BMTZgiYGwVMiAHLD5FcIWEFIHFiFEEjmugD+sJKQIrBIJ5QPohEHfiUgQCAAEGAAbEfCwI4OIGAAAAAElFTkSuQmCC) no-repeat !important;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .president-slid .carousel-control-next-icon,
  .president-slid .carousel-control-prev-icon {
    display: inline-block;
    width: 10px;
    height: 16px;
  }
  .blue-over-img img {
   width: 190px;
   object-fit: cover;
    height: 250px;
    border-radius: 10px;
  }
  .crown-above {
    position: absolute;
    top: 5px;
    width: 35px;
    height: 35px;
    right: 5px;
    background: #fff;
    border-radius: 50%;
    padding: 5px;
  }
  
  .crown-above img {
    width: 25px;
    height: 25px;
  }
  .bg-blue-slid {
    background: #3482e8;
    color: #fff;
    padding: 19px;
    position: relative;
    border-radius: 10px;
    margin-top: 25px;
    height: 200px;
  }
  
  .blue-over-img {
   margin-left: 10px;
    margin-top: -44px;
    position: relative;
  }
  
  
  
  .bg-blue-slid h4 {
    font-size: 19px;
    font-weight: 600;
    margin-bottom: 21px;
    margin-top: 10px;
  }
  
  .bg-blue-slid h6 {
    font-size: 13px;
    line-height: 10px;
    font-weight: 100;
  }
  
  .total-count {
    margin-top: 20px;
    font-size: 24px;
    position: relative;
    font-weight: 600;
    line-height: 21px;
    padding-right: 19px;
    padding-left: 19px;
   
  }
  
  .total-count span {
    font-size: 13px;
    font-weight: 100;
  }
  .left-spc{
    margin-left:30px;
  }
  .total-count:after {
    content: "";
  position: absolute;
  right: 0;
  background: #fff;
  height: 100%;
  top: 0;
  width: 1px;
  }
  .total-count:first-child {
    padding-left: 0;
  }
  .total-count:last-child:after {
    content: none;
  }
  .slider-sub-names img {
    border-radius: 50%;
    width: 50px;
    object-fit: cover;
    height: 50px;
  }
  .slider-sub-names h4 {
    font-size: 17px;
    margin-top: 0;
  }
  
  .slider-sub-names h5 {
    text-align: right;
    margin-top: 20px;
    font-size: 18px;
  }
  
  .slider-sub-names h6 {
    font-size: 13px;
    line-height: 7px;
  }
  
  .slider-sub-names {
    border-bottom: 1px solid #f6f6f6;
    margin-bottom: 5px;
    padding-top: 6px;
    padding-bottom: 8px;
  }
  .slider-sub-names:last-child {
    border-bottom: 0px;
  }
  @media (max-width: 1200px){
    .president-hds:before, .president-hds:after{
    width: 35%;
    }
    .left-spc {
      margin-left: 8px;
  }
    }
    @media (max-width: 991px){
      .blue-over-img{
        margin-left: 0;
      }
      .slider-sub-names h6 {
        line-height: 16px;
    }
    }
    @media  (max-width:991px){
  
      .slider-left{
        display: block !important;
        color:#000;
        text-align: center;
      }
      .bg-blue-slid .crown-above{
        right:10px;
      }
      .bg-gray-slid .crown-above{
        right:28px;
      }
    }
    @media (max-width: 768px){
      .president-slid {
        padding: 50px 20px;
      }
      .slider-right-list{
        margin-bottom: 25px;
      }
      .other-list{
        margin-top:40px;
      }
    .president-hds:before, .president-hds:after{
    width: 30%;
    }
    .president-slid .carousel-control-prev,
    .president-slid .carousel-control-next {
      display: none;
        }
        .president-slid .carousel-indicators{
          display:flex;
        }
        .carousel-indicators .active {
          background-color: #007bff !important;
      }
      .carousel-indicators li{
        background-color: #007bff !important;
      }
    }
  
    @media (max-width: 991px){
      .total-count{
        margin-top: 0;
      }
        .blue-over-img{
    width: 200px;
    margin: -44px auto 0 auto !important;
  }
      .slider-left{
        display: block !important;
        color:#000;
        text-align: center;
      }
      .left-spc{
        display: block;
        float: left;
        width: 100%;
      }
  
    }
    @media (max-width: 480px){
      slider-sub-names h6 {
        line-height: 15px;
    }
      .crown-above {
        right: 10px;
    }
    .bg-gray-slid .crown-above{
      right:10px;
    }
    .blue-over-img{
    width: 200px;
    margin: -44px auto 0 auto;
  }
    }
    @media (max-width: 380px){
      .slider-sub-names h4{
        font-size: 14px;
      }
      .slider-sub-names h5{
        margin-top: 5px;
        font-size: 14px;
      }
      .blue-over-img{
        width: 100%;
        margin: -44px auto 0 auto;
      }
      .blue-over-img img {
        width: 100%;
      }
    }
  
    /*****08-06-2023*************************/
    .bg-gray-slid .blue-over-img img {
      width: 120px;
      height: 130px;
      object-fit: cover;
     }
     .bg-gray-slid  .blue-over-img {
      margin-left: 10px;
      margin-top: -32px;
     }
     .bg-gray-slid {
      background: #f2f5fb;
      color: #000;
      padding: 19px;
      position: relative;
      border-radius: 10px;
      margin-top: 25px;
      height: 106px;
      display: block;
      float: left;
      width: 100%;
      margin-bottom: 15px;
  }
  
  .bg-gray-slid h4 {
    font-size: 17px;
  }
  
  .bg-gray-slid .left-spc {
    margin-left: 10px;
  }
  
  .bg-gray-slid h6 {
    font-size: 13px;
    font-weight: 100;
  }
  
  .bg-gray-slid .total-count {
    margin-top: 6px;
    text-align: right;
    padding-right: 8px;
  }
  .bg-gray-slid .crown-above img {
    width: 25px !important;
    height: 25px !important;
  }
  .grey-height-over{
    height: 439px !important;
    padding-right: 15px;
      overflow-y: scroll;
  }
  @media (max-width: 1200px){
    .bg-gray-slid .blue-over-img {
      margin-left: 0px;
   
  }
  }
  @media  (max-width: 991px){
    .bg-gray-slid{
      height: auto;
    }
    .bg-gray-slid .total-count {
      text-align: center;
    }
    .gray-over-img {
      width: 200px;
      margin: -32px auto 0 auto;
  }
    .bg-gray-slid{
      height: auto;
    }
    .bg-gray-slid .blue-over-img img {
      width: 153px;
      height: 180px;
  }
  
  }
  @media (max-width: 380px){
    .bg-gray-slid .slider-sub-names h4{
      font-size: 14px;
    }
    .bg-gray-slid .slider-sub-names h5{
      margin-top: 5px;
      font-size: 14px;
    }
    .bg-gray-slid .blue-over-img{
      width: 100%;
    }
    .bg-gray-slid .blue-over-img img {
      width: 100%;
    }
  }
  .scrollbar {
    overflow-y: scroll;
  }
  .scrollbar-primary {
    scrollbar-color: #c4cee0 #f5f5f5;
  }
  .scrollbar-primary::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
   }
  
  .scrollbar-primary::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #c4cee0; 
  }
input#formBasicCheckbox\ green-form-chk-agree + label::before {
    border: 3px solid red;
  }
.timer-box .r-block{
    background: #FF4141;
    color: #ffffff;
    width: 40px;
    margin: 0 auto;
    font-weight: bold;
    height: 40px;
    border-radius: 8px;
    font-size: 18px;
} 
.timer-box{
    color: #FF4141;
    font-size: 13px;
} 
.timer-box .colon{
    font-weight: bold;
    font-size: 25px;
}
